.container {
  display: flex;

  justify-content: center;
  background-color: #000;
  height: auto;
  max-width: 100%;
  text-align: center;
  /* align-content: center !important; */
}

.card-weather {
  /* width: 100%; */
  text-align: center;
  height: 50%;
  background-color: rgb(231, 229, 229);
  margin-top: 20%;
  border-radius: 10px;
  box-shadow: 0px 5px 8px 6px rgba(128, 120, 120, 0.2),
    4px 5px 7px 6px rgba(128, 120, 120, 0.2);
}

.Weather-report {
  display: flex;
  flex-direction: row;
}
.temperature {
  background-color: #000;
  color: #fff;
  padding: 20px;
}
.temperature span {
  font-size: 3rem;
}
.description {
  background-color: #000;
  color: #fff;
  padding: 30px;

  font-style: normal;
}
.condition {
  font-size: 1.3rem;
}
.location {
  font-size: 0.7rem;
}
.date {
  background-color: aquamarine;
  font-size: 1.3rem;
  padding: 20px;
}
.grid {
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  justify-content: space-around;
}
.grid-1 {
  width: 70px;
  height: 70px;
}
.search {
  margin: 98px -329px 40px 10px;
  height: 30px;
  display: flex;
  justify-content: space-around;
}
.search > button {
  background-color: #fff;
  height: 1.8rem;
  margin-left: 5px;
  text-align: center;
  padding: 2px;
  font-size: 0.9rem;
}
@media only screen and (max-width: 600px) {
  .search {
    margin: 28px -257px 40px 10px;
  }
}
